/* Genel uygulama tasarımı */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #1a1a2e;
  color: #fff;
}

/* İçeriği ortalamak ve düzgün yerleştirmek için */
.container {
  position: relative; /* İçeriğin animasyonun üstünde olmasını sağlarız */
  z-index: 1; /* İçeriği ön plana getirir */
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: justify;
}

/* Parçacık animasyonu için canvas */
canvas {
  position: absolute; /* Ekranın tamamını kapsar */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Arka planda kalır */
  pointer-events: none; /* Tıklanamaz, etkileşime girmez */
}

/* Başlık ve içerik */
h1, h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #f9f9f9;
}

p {
  font-size: 18px;
  line-height: 1.6;
  color: #d1d1d1;
}

.text-center {
  text-align: center;
}

/* Masonry grid için gerekli stil */
.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 30px;
}

/* Arka plan ve layout */
.bg-gradient-to-br {
  background: linear-gradient(to bottom right, #141E30, #243B55);
  min-height: 100vh;
}

.bg-opacity-10 {
  background-color: rgba(255, 255, 255, 0.1);
}

.rounded-lg {
  border-radius: 8px;
}

.backdrop-filter {
  backdrop-filter: blur(10px);
}

.backdrop-blur-lg {
  backdrop-filter: blur(10px);
}

/* Button */
button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #2980b9;
}

/* Input alanı */
input {
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Margin ve padding düzenlemeleri */
.mt-8 {
  margin-top: 2rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.p-8 {
  padding: 2rem;
}

/* Footer (Hazırlayan) */
footer {
  margin-top: 20px;
  font-size: 14px;
  color: #bbb;
  text-align: center;
}
.label-left {
  text-align: left;
}
.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 30px;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

